<template>
  <div>
    <el-row
      v-for="(item, index) in items"
      :key="index"
      type="flex"
      class="dashboard__item"
      justify="space-between"
      align="middle">
      <router-link
        class="dashboard__item-link"
        :to="{ name: entity, params: { type: item.acf.typology, id: item.id, item: item } }">
        <div class="dashboard__item-date">{{ itemDate(item) }}</div>
        <div class="dashboard__item-title">{{ item.title.rendered }}</div>
        <div class="dashboard__item-subtitle">{{ item.acf.subtitle }}</div>
      </router-link>
    </el-row>
  </div>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'DashboardItem',
  props: [
    'items',
    'entity',
  ],
  methods: {
    itemDate(item) {
      if (item.type && item.type === 'market-trend'
        && (item.acf.typology === 'weekly' || item.acf.typology === 'outlook')
      ) {
        return `${i18n.t('global.from')} 
          ${item.acf.date_from} 
          ${i18n.t('global.to')} 
          ${item.acf.date_to}`;
      }
      return item.acf.date;
    },
  },
};
</script>
