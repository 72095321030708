<template>
  <div class="dashboard">
    <div class="dashboard__user-info">
      <p>{{ $t('dashboard.user') }} {{ currentUser.user_display_name }}</p>
      <p>{{ $t('dashboard.group') }} {{ currentUser.user_group.title }}</p>
    </div>
    <el-row type="flex" :gutter="20">
      <el-col :span="12" class="dashboard__col">
        <div
          class="dashboard__box dashboard__box--premiums-paid"
          v-loading="isLoadingStatistics">
          <div
            class="
              dashboard__icon
              icon-premi-versati
              dashboard__icon--big
              dashboard__icon--premiums-paid">
          </div>
          <div>
            <p class="dashboard__box-title dashboard__box-title--small">
              {{ $t('dashboard.premiumsPaid') }}
            </p>
            <p class="dashboard__amount">
              € {{ newBusinessAmount }}
            </p>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="dashboard__col">
        <div
          class="dashboard__box dashboard__box--portfolio"
          v-loading="isLoadingStatistics">
          <div class="dashboard__icon icon-euro dashboard__icon--big dashboard__icon--portfolio">
          </div>
          <div>
            <p class="dashboard__box-title dashboard__box-title--small">
              {{ $t('dashboard.portfolio') }}
            </p>
            <p class="dashboard__amount">
              € {{ portfolioAmount }}
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="dashboard__note">
      {{ $t('dashboard.dataDate') }} {{ currentOptions.last_update }}
    </div>

    <el-row type="flex" :gutter="20">
      <el-col :span="12" class="dashboard__col">
        <div class="dashboard__box dashboard__box--communications">
          <p class="dashboard__box-title dashboard__box-title--with-icon">
            <span class="dashboard__box-icon icon-communications"></span>
            <span>{{ $t('navbar.communications') }}</span>
          </p>
          <div
            class="dashboard__items"
            v-loading="isLoadingCommunications">
            <div class="dashboard__items-inner">
              <div v-if="isEmptyResponseCommunications">{{ $t('global.noResults') }}</div>
              <DashboardItem :items="communications" :entity="entityCommunications"></DashboardItem>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="dashboard__col">
        <div class="dashboard__box dashboard__box--investment">
          <p class="dashboard__box-title dashboard__box-title--with-icon">
            <span class="dashboard__box-icon icon-investment"></span>
            <span>{{ $t('navbar.investmentAdvisory') }}</span>
          </p>
          <div
            class="dashboard__items"
            v-loading="isLoadingInvestments">
            <div class="dashboard__items-inner">
              <div v-if="isEmptyResponseInvestments">{{ $t('global.noResults') }}</div>
              <DashboardItem :items="investments" :entity="entityInvestments"></DashboardItem>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="20">
      <el-col :span="24" class="dashboard__col">
        <div class="dashboard__box dashboard__box--company">
          <p class="dashboard__box-title">
            <span>{{ $t('investmentAdvisory.company') }}</span>
          </p>
          <div class="dashboard__items">
            <DownloadFileRow
              :items="currentUser.user_group.company_profile"
              :isEmpty="isEmpty">
            </DownloadFileRow>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { communicationsApi, investmentAdvisoryApi, usersApi } from '@/api';
import DashboardItem from '@/components/DashboardItem.vue';
import DownloadFileRow from '@/components/DownloadFileRow.vue';

export default {
  name: 'Dashboard',
  data() {
    return {
      communications: [],
      investments: [],
      perPage: 3, // Default page post limit
      isLoadingCommunications: false,
      isLoadingInvestments: false,
      isLoadingStatistics: false,
      isEmptyResponseCommunications: false,
      isEmptyResponseInvestments: false,
      entityCommunications: 'Communication',
      entityInvestments: 'MarketTrend',
      metaQuery: true,
      newBusinessAmount: '0',
      portfolioAmount: '0',
    };
  },
  components: {
    DashboardItem,
    DownloadFileRow,
  },
  created() {
    this.getRemoteItems();
  },
  beforeRouteUpdate(to, from, next) {
    this.getRemoteItems();
    next();
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
    ...mapGetters('options', ['currentOptions']),
    isEmpty() {
      return (this.currentUser.user_group.company_profile
        && this.currentUser.user_group.company_profile.length === 0);
    },
  },
  methods: {
    async getRemoteItems() {
      try {
        this.isLoadingCommunications = true;
        this.isLoadingInvestments = true;
        this.isLoadingStatistics = true;
        const userParams = {
          operator_code: this.currentUser.user_code,
          group_code: this.currentUser.user_group.code,
          user_role: this.currentUser.user_reserved_area_role,
        };
        const statisticsResponse = await usersApi.getStatistics(userParams);
        this.newBusinessAmount = statisticsResponse.data.user.new_business_total;
        this.portfolioAmount = statisticsResponse.data.user.assets_under_management;
        this.isLoadingStatistics = false;

        const params = {
          per_page: this.perPage,
          meta_query: this.metaQuery,
          group: this.currentUser.user_group.id,
        };
        const communicationsResponse = await communicationsApi.getCommunications(params);
        this.communications = communicationsResponse.data;
        this.isLoadingCommunications = false;

        const investmentsResponse = await investmentAdvisoryApi.getMarketTrends(params);
        this.investments = investmentsResponse.data;
        this.isLoadingInvestments = false;
        this.isEmptyResponseCommunications = (this.communications.length === 0);
        this.isEmptyResponseInvestments = (this.investments.length === 0);
      } catch (error) {
        this.isLoadingCommunications = false;
        this.isLoadingInvestments = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
  },
};
</script>
